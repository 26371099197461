<template>
  <div id="Manage">
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <!-- <el-button type="primary"
                     size="small"
                     icon="el-icon-circle-plus-outline">添加酒店/民宿</el-button> -->
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->
        </div>
        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    ref="select"
                    class="filtrateInput">
          <el-button type="primary">搜 索</el-button>
          <el-button>导出</el-button>
          <el-button type="text">查看已导出列表</el-button>
          <el-button type="text">重置筛选条件</el-button>
        </CommonForm>

      </template>
      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @tabHandleClick="swichTable"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonForm from 'components/CommonForm.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
import { getuserOrderDetail } from 'api/orderManagement'
export default {
  components: {
    CommonForm,
    CommonManageMain,
    CommonTabForm
  },
  data () {
    return {
      PayOrder: null,
      tabIndex: null,//页签
      searchForm: {
        no: '',
        order_title: '',
        username: '',
        mobile: '',
        source: '',
        pay_type: ''
      },
      tableData: [],
      searchformLabel: [
        {
          model: 'order_id',
          label: '订单ID',
          width: '304px',
        },
        {
          model: 'order_user',
          label: '订单用户ID',
          width: '304px',
        },
        {
          model: 'order_data_agent_id',
          label: '代理商ID',
          width: '304px',
        },
        {
          model: 'pay_provider_id',
          label: '支付提供商ID',
          width: '304px',
        },
        {
          model: 'no',
          label: '订单编号',
          width: '304px',
        },
        {
          model: 'pay_trade_no',
          label: '交易流水号',
          width: '304px',
        },

      ],
      tableFormLabel: [
        {
          name: 'first',
          label: "未支付",
          type: 'table',
          total: 'total',
          tableLabel: [
            {
              prop: 'id',
              label: '订单ID',
            },
            {
              prop: 'user_id',
              label: '	用户ID',
            },
            {
              prop: 'data_agent_id',
              label: '代理商ID',
            },
            {
              prop: 'pay_provider_id',
              label: '支付提供商ID',
              width: '150px'
            },
            {
              prop: 'no',
              label: '订单编号',
              width: '150px'

            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'original_amount',
              label: '原始充值金额',
              width: '130px'

            },
            {
              prop: 'amount',
              label: '实际付款金额',
              width: '130px'

            },
            {
              prop: 'pay_money',
              label: '支付金额',
            },
            {
              prop: 'gift_amount',
              label: '赠送金额',
            },
            {
              prop: 'gift_integral',
              label: '赠送积分',
            },

            {
              prop: 'operation',
              label: '操作',
              width: '70px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: false,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'second',
          label: "已支付",
          type: 'table',
          tableLabel: [
            {
              prop: 'id',
              label: '订单ID',
            },
            {
              prop: 'user_id',
              label: '	用户ID',
            },
            {
              prop: 'data_agent_id',
              label: '代理商ID',
            },
            {
              prop: 'pay_provider_id',
              label: '支付提供商ID',
              width: '150px'
            },
            {
              prop: 'no',
              label: '订单编号',
              width: '150px'

            },
            {
              prop: 'pay_trade_no',
              label: '交易流水号',
              width: '150px'
            },

            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'original_amount',
              label: '原始充值金额',
              width: '130px'

            },
            {
              prop: 'amount',
              label: '实际付款金额',
              width: '130px'

            },
            {
              prop: 'pay_money',
              label: '支付金额',
            },
            {
              prop: 'gift_amount',
              label: '赠送金额',
            },
            {
              prop: 'gift_integral',
              label: '赠送积分',
            },

            {
              prop: 'operation',
              label: '操作',
              width: '70px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: false,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },

      ],
    }
  },
  created () {
    this.getuserOrderDetail()
  },
  updated () {

  },
  methods: {
    getuserOrderDetail (data) {
      getuserOrderDetail(data).then(res => {
        this.PayOrder = res.data.data
        console.log(this.PayOrder, "充值订单");
      })

    },
    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },
    swichTable (tab) {
      this.tabIndex = tab.index
      console.log(this.tabIndex)
      // const name = tab.name
      // this.activeTableName = name

      //刷新
      if (this.tabIndex == 1) {
        let data = {
          status: 1
        }
        this.getuserOrderDetail(data)
      } else if (this.tabIndex == 0) {
        let data = {
          status: 0
        }
        this.getuserOrderDetail(data)
      }
    },
  },
  watch: {
    //将字段转换为Elementui能用的字段
    PayOrder: function () {
      this.tableData = this.dataFormatDeal(
        this.PayOrder.list,
        {
          status: 'state',
        },
        0
      )
    },

  },
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .content {
    height: 100%;
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
  /deep/ .el-table .cell.el-tooltip {
    white-space: normal;
    text-align: left;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 24px 8px;
    // margin-top: 51px;
    width: 100%;
    margin-left: -128px;
    margin-bottom: 32px;
  }
}
/deep/ .el-dialog {
  width: 45%;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    // .el-form-item {
    //   border: 1px solid red;
    //   // width: 70%;
    //   // flex: 0 0 40%;
    //   // margin-left: 20px;
    // }
  }
  .el-form {
    // width: 100%;
  }
}
</style>